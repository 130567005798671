.swal2-title {
  line-height: 40px !important;
}

.home-video {
  border-radius: 10px;
  object-fit: initial;
  width: 100%;
  height: 260px;
}
